import dynamic from 'next/dynamic';

import * as React from 'react';
import { useFela, } from 'react-fela';
import {
  Aka,
  AntonioC,
  Beavis,
  BenderBig,
  Boxy, // no change
  Calculon,
  Barbados,
  Brannigan,
  Brannigan2,
  Butthead,
  Hubert,
  Image,
  ClickTrackerWrapper,
  Conrad, // no change
  Donatello,
  Donbot, // no change
  Elzar,
  ElementGroup,
  Error,
  HdcFooter,
  Fry,
  Gamal, // no change
  AdSlot,
  AdSlotPrioritySelector,
  GridContainer,
  GridElement,
  Hawking, // no change
  Hermes,
  Hermes2,
  Hypnotoad, // no change
  HtmlElement,
  Iframe,
  Amy,
  Inez,
  Interactive,
  Kroker, // t
  Leonardo,
  List,
  CatchupListElement,
  Lrrr2,
  MainBlockHdc,
  Masthead,
  Marco,
  Michelangelo,
  MobileListWrapper,
  Mom, // no change
  Morbo, // no change
  Mousepad, // no change
  // Nibbler,
  OutbrainHomepage,
  Panucci, // no change
  Pazuzu,
  Petunia, // t
  Roberto,
  RssFeed,
  Schoonhoven,
  Slugs, //
  TabElement,
  TopNews,
  Vogel, // no change
  Zapp, //
  Zombie,
  Weekend,
  WidePageClickTracker,
  LayoutContainer,
  BreakingNewsBox,
  // icons
  IconHaaretzComLogoFull,
  IconAlefLogoTransparent,
  IconHtzLoader,

  // utils
  getElementsFactory,
} from '@haaretz/htz-components';

const ImageGalleryTeaser = dynamic(() => import('../components/ImageGalleryTeaser/ImageGalleryteaser'));

// eslint-disable-next-line react/prop-types
function MastheadLogo() {
  const { theme, } = useFela();
  return (
    <IconHaaretzComLogoFull
      attrs={{ 'aria-hidden': true, }}
      size={[ { until: 'l', value: 4, }, { from: 'l', value: 4.86, }, ]}
      color="primary"
      miscStyles={{
        marginBottom: [ { from: 's', until: 'l', value: '.5rem', }, ],
        transitionProperty: 'fill, color,  height, width',
        ...theme.getDelay('transition', -1),
        ...theme.getDuration('transition', -1),
        ...theme.getTimingFunction('transition', 'linear'),
        willChange: 'transform',
        color: [ { until: 's', value: 'white', }, ],
        fill: [ { until: 'l', value: 'transparent', }, { from: 'l', value: [ 'primary', 'base', ], }, ],
      }}
    />
  );
}

// eslint-disable-next-line react/prop-types
function HdcHomePageBreakingNews({ properties, ...props }) {
  return (
    <LayoutContainer miscStyles={{
      padding: [ { from: 's', value: '1rem', }, ],
    }}
    >
      <BreakingNewsBox {...properties} {...props} />
    </LayoutContainer>
  );
}

const elements = new Map([
  // General components
  [ 'PageNavigation', Masthead, ],
  [ 'MainBlock', MainBlockHdc, ],
  //   [ 'com.tm.BreakingNewsBox', HdcHomePageBreakingNews, ],
  [ 'HeaderNewsList', props => {
    if (props.kind === 'breakingNews') return <HdcHomePageBreakingNews {...props} />;
    return <TopNews {...props} />;
  }, ],
  [ 'breakingNews', HdcHomePageBreakingNews, ],
  [ 'topNews', TopNews, ],

  [ 'ClickTrackerWrapper', ClickTrackerWrapper, ],
  //   [ 'widePageClickTrackerBannersWrapper', WidePageClickTracker, ],
  //   [ 'com.tm.ArticleInteractiveHtmlElement', Interactive, ],
  //   [ 'interactive', Interactive, ],
  [ 'AdSlot', AdSlot, ],
  [ 'AdSlotPrioritySelector', AdSlotPrioritySelector, ],
  [ 'Outbrain', OutbrainHomepage, ],
  //   [ 'com.polobase.whtzMobileSiteListsWrapper', MobileListWrapper, ],
  //   [ 'com.tm.ElementGroup', ElementGroup, ],
  //   [ 'com.tm.ExternalRssElement', RssFeed, ],
  [ 'PageFooter', HdcFooter, ],
  [ 'GridItem', GridElement, ],
  [ 'Grid', GridContainer, ],
  [ 'HtmlElement', HtmlElement, ],
  [ 'StaticElement', HtmlElement, ],
  //   [ 'com.tm.IframElement', Iframe, ],
  //   [ 'com.tm.ResubscribeElementsGroup', ],
  [ 'TabsContainer', TabElement, ],
  [ 'CatchupList', CatchupListElement, ],
  [ 'htz_list_List', List, ],
  [ 'htz_image_Image', Image, ],

  [ 'com.tm.promotion.banner.BottomRuler', ],
  [ 'com.tm.promotion.banner.TopRuler', ],
  [ 'error', Error, ],
  [ 'imageGalleryTeaser', ImageGalleryTeaser, ],

  // list views
  [ 'Aka_HDC', Aka, ],
  [ 'AntonioC', AntonioC, ],
  [ 'Beavis', Beavis, ],
  [ 'BenderBig', BenderBig, ],

  [ 'Boxy', props => <Boxy {...props} viewProps={{ hasMarginTop: true, }} />, ],
  [ 'Calculon', Calculon, ],
  [ 'Barbados', Barbados, ],
  [ 'Brannigan', Brannigan, ],
  [ 'Brannigan2', Brannigan2, ],
  [ 'Conrad', Conrad, ],
  [ 'Donatello', Donatello, ],
  [ 'Donbot', Donbot, ],
  [ 'Elzar', Elzar, ],
  [ 'Fry', Fry, ],
  [ 'Hawking', Hawking, ],
  [ 'Hermes', Hermes, ],
  [ 'Hypnotoad', Hypnotoad, ],
  [ 'Hermes-2', Hermes2, ],
  [ 'Gamal', Gamal, ],
  [ 'Kroker', Kroker, ],
  [ 'Lrrr', Lrrr2, ],
  [ 'Lrrr2', Lrrr2, ],
  [ 'Marco', Marco, ],
  [ 'Michaelangelo', Michelangelo, ],
  [ 'Mom', Mom, ],
  [ 'Mousepad', Mousepad, ],
  // [ 'Nibbler', Nibbler, ],
  [ 'Morbo', Morbo, ],
  [ 'Pazuzu', Pazuzu, ],
  [ 'Panucci', Panucci, ],
  [ 'Butthead', Butthead, ],
  [ 'Petunia', Petunia, ],
  [ 'Schoonhoven', Schoonhoven, ],
  [ 'Hubert', Hubert, ],
  [ 'Slugs', Slugs, ],
  [ 'Roberto', Roberto, ],
  [ 'Kif', Weekend, ],
  [ 'Full_Coverage', Weekend, ],
  [ 'Inez', Inez, ],
  [ 'Amy', Amy, ],
  [ 'Leonardo', Leonardo, ],
  [ 'Zapp', Zapp, ],
  [ 'Zombie', Zombie, ],
  [ 'Vogel', Vogel, ],
  // Icons
  [ 'mastheadLogo', MastheadLogo, ],
  [ 'loaderIcon', IconHtzLoader, ],
  [ 'siteLogo', IconHaaretzComLogoFull, ],
  [ 'siteIconTransparent', IconAlefLogoTransparent, ],

]);

const getHomepageElements = getElementsFactory(elements);

export default getHomepageElements;
